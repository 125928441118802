import * as React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { Layout, Section, Stage } from '../components/layout';
import { Copy } from '../components/Text';
import { Button } from '../components/Button';
import { TargetAwareLink } from '../components/Link';
import { graphql } from 'gatsby';

const ErrorPage = () => {
    const { t } = useI18next([ 'common', 'footer' ]);
    const title = t('common:error.other.title');
    return (
        <Layout
            title={title}
            seoProps={{
                noIndex: true,
                noFollow: true,
            }}
        >
            <Stage.Small title={title} />
            <Section>
                <Copy center>
                    <p>
                        {t('common:error.other.text')}
                    </p>
                    {/* @ts-ignore */}
                    <Button as={TargetAwareLink} href="/">{t('common:error.other.button')}</Button>
                </Copy>
            </Section>
        </Layout>
    );
};

export default ErrorPage;


export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
